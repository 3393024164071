import { EntityComment } from '@app/models';
import { JSONContent } from '@tiptap/core';
import {
  get, post, put, patch, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  list: async function(universeId: string, entityId: string): Promise<Array<EntityComment>> {
    return get(`${COW_API_URL}/universes/${universeId}/entities/${entityId}/comments`).then(
      (comments: any) => comments.map((comment: any) => new EntityComment(comment)),
    );
  },
  create: async function(universeId: string, entityId: string, document: JSONContent): Promise<EntityComment> {
    return new EntityComment(await post(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/comments`,
      { body: JSON.stringify({ document }) },
    ));
  },
  update: async function(
    universeId: string,
    entityId: string,
    commentId: string,
    document: JSONContent,
  ): Promise<EntityComment> {
    return put(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/comments/${commentId}`,
      { body: JSON.stringify({ document }) },
    ).then((comment: any) => new EntityComment(comment));
  },
  delete: async function(universeId: string, entityId: string, commentId: string): Promise<void> {
    return delete_(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/comments/${commentId}`,
    );
  },
  undoDelete: async function(universeId: string, entityId: string, commentId: string): Promise<EntityComment> {
    return new EntityComment(await patch(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/comments/deleted/${commentId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    ));
  },
};
