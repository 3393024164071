import ImageRepository from '@app/repositories/imageRepository';
import CategoryRepository from '@app/repositories/categoryRepository';
import EntityTypeRepository from '@app/repositories/entityTypeRepository';
import store from '@app/store';
import { Category, Image } from '@app/models';

export default class EntityType {
  id: string;

  userId: string;

  universeId: string;

  name: string;

  public: boolean;

  pluralName: string;

  position: number;

  parentId: string;

  image?: Image;

  categories: Array<Category>;

  constructor(obj: any) {
    this.id = obj.id;
    this.userId = obj.userId;
    this.universeId = obj.universeId;
    this.name = obj.name;
    this.pluralName = obj.pluralName;
    this.position = obj.position;
    this.parentId = obj.parentId;
    this.public = obj.public;
    this.image = obj.image ? new Image(obj.image) : undefined;
    this.categories = obj.categories?.map(
      (category: any) => new Category(category),
    );
  }

  async rename(newName: string) {
    this.name = newName;
    return EntityTypeRepository.update(
      {
        name: this.name,
        id: this.id,
      },
    ).then((updatedInfo) => {
      this.pluralName = updatedInfo.pluralName;
    });
  }

  renamePlural(newName: string) {
    this.pluralName = newName;
    return EntityTypeRepository.update(
      {
        pluralName: this.pluralName,
        id: this.id,
      },
    );
  }

  async createCategory(category: Partial<Category>) {
    return CategoryRepository.create(this.id, category)
      .then((createdCategory) => {
        this.categories.push(createdCategory);
        return createdCategory;
      });
  }

  async addImage(file: File) {
    return ImageRepository.setEntityTypeImage(this.universeId, this.id, file).then((response) => {
      this.image = new Image(response);
    });
  }

  async addImageByUrl(url: string): Promise<void> {
    return ImageRepository.setEntityTypeImageByUrl(this.universeId, this.id, url).then((response) => {
      this.image = new Image(response);
    });
  }

  getImageThumbnailUrl(): string {
    if (this.image) {
      return `${COW_USER_IMAGE_URL}/thumbnails/${this.image.id}.${this.image.thumbnailExtension}`;
    }

    return '/images/default.jpg';
  }

  getImageUrl(): string | undefined {
    if (this.image) {
      return `${COW_USER_IMAGE_URL}/${this.image.id}.${this.image.extension}`;
    }
  }

  getImageUrls(): Array<string> {
    const image = this.getImageUrl();
    if (image) {
      return [image];
    }

    return [];
  }

  usesDefaultImage(): boolean {
    return !this.image;
  }

  async deleteImage(): Promise<void> {
    if (this.image) {
      const deletedImage = this.image;
      return ImageRepository.deleteEntityTypeImage(this.universeId, this.id, this.image.id).then(() => {
        this.image = undefined;
        store.state.toasts.push(
          {
            componentName: 'UndoDeleteToast',
            key: deletedImage.id,
            message: `Deleted default image for "${this.pluralName}"`,
            undoDelete: async() => {
              await ImageRepository.undoDeleteEntityTypeImage(this.universeId, this.id, deletedImage.id);
              this.image = deletedImage;
            },
          },
        );
      });
    }
  }

  getCategory(categoryId: string): Category | undefined {
    return this.categories.find((category: Category) => category.id === categoryId);
  }

  async deleteCategory(category: Category): Promise<void> {
    return CategoryRepository.delete(this.id, category.id).then(() => {
      const index = this.categories.findIndex((candidateCategory) => candidateCategory.id === category.id);
      this.categories.splice(index, 1);
      store.state.toasts.push(
        {
          componentName: 'UndoDeleteToast',
          key: category.id,
          message: `Deleted category "${category.name}" in ${this.pluralName}`,
          undoDelete: async() => {
            await CategoryRepository.undoDelete(this.id, category.id);

            let insertIndex = 0;
            for (const existingCategory of this.categories) {
              if (existingCategory.position > category.position) {
                break;
              }
              insertIndex += 1;
            }

            this.categories.splice(insertIndex, 0, category);
          },
        },
      );
    });
  }

  getField(fieldId: string) {
    for (const category of this.categories) {
      for (const field of category.fields) {
        if (field.id === fieldId) {
          return field;
        }
      }
    }
    return undefined;
  }
}
