class ClientError extends Error {
  constructor(message: string) {
    super();
    Object.setPrototypeOf(this, ClientError.prototype);
    this.name = 'ClientError';
    this.message = message;
  }
}

class CowApiError extends Error {
  readonly errorId?: string;

  readonly httpStatus: number;

  readonly httpErrorBody: any;

  constructor(httpStatus: number, errorResponse: any) {
    super(errorResponse.error);
    Object.setPrototypeOf(this, CowApiError.prototype);
    this.name = 'CowApiError';
    this.errorId = errorResponse.errorId;
    this.httpStatus = httpStatus;
    this.httpErrorBody = errorResponse;
  }
}

class NetworkError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, NetworkError.prototype);
    this.name = 'NetworkError';
  }
}

enum HttpError {
  Unauthorized,
  Forbidden,
  NotFound,
  Gone,
  ContentTooLarge,
}

export {
  ClientError,
  CowApiError,
  NetworkError,
  HttpError,
};
