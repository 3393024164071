export default class RelationshipRequirement {
  id: string;

  fieldId: string;

  toEntityTypeId: string;

  reciprocalFieldId: string | undefined | null;

  constructor(obj: any) {
    this.id = obj.id;
    this.fieldId = obj.fieldId;
    this.reciprocalFieldId = obj.reciprocalFieldId;
    this.toEntityTypeId = obj.toEntityTypeId;
  }
}
