import { CowApiError, NetworkError } from './errorHandling';

const wrapper = {
  fetch: async function<T>(
    url: string,
    overrides: RequestInit,
  ): Promise<T> {
    const controller = new AbortController();

    const options: RequestInit = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: controller.signal,
    };
    Object.assign(options, overrides);

    // 45 second timeout:
    setTimeout(() => controller.abort(), 45000);

    let response = null;
    try {
      response = await fetch(url, options);
    }
    catch (error) {
      // Fetch returns a rejected promise on network failures (or abort)
      // In this case, we throw our own error so that we can catch it elsewhere
      throw new NetworkError();
    }

    if (!response.ok) {
      const errorResponse = await response.json();

      throw new CowApiError(response.status, errorResponse);
    }

    return response.json();
  },
};

async function get<T>(url: string, overrides: RequestInit = {}) {
  overrides.method = 'GET';
  return wrapper.fetch<T>(url, overrides);
}

async function post<T>(url: string, overrides: RequestInit) {
  overrides.method = 'POST';
  return wrapper.fetch<T>(url, overrides);
}

async function put<T>(url: string, overrides: RequestInit) {
  overrides.method = 'PUT';
  return wrapper.fetch<T>(url, overrides);
}

async function patch<T>(url: string, overrides: RequestInit) {
  overrides.method = 'PATCH';
  return wrapper.fetch<T>(url, overrides);
}

async function delete_<T>(url: string, overrides: RequestInit = {}) {
  overrides.method = 'DELETE';
  return wrapper.fetch<T>(url, overrides);
}

export {
  wrapper,
  get,
  post,
  put,
  patch,
  delete_,
};
