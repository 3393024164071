import UniverseRepository from '@app/repositories/universeRepository';
import EntityTypeRepository from '@app/repositories/entityTypeRepository';
import ImageRepository from '@app/repositories/imageRepository';
import store from '@app/store';
import {
  PowerUp, Authorization, EntityType, AbridgedEntity, User, Image,
} from '.';

export default class Universe {
  id: string;

  name: string;

  description: object;

  userId: string;

  images: Image[];

  authorizations: Array<Authorization>;

  powerUps: Array<PowerUp>;

  constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.description = obj.description;
    this.userId = obj.userId;
    this.authorizations = obj.authorizations.map((authorization: any) => new Authorization(authorization));
    this.powerUps = obj.powerUps;
    if (obj.images) {
      this.images = obj.images.map((image: any) => new Image(image));
    }
    else {
      this.images = [];
    }
  }

  async rename(newName: string): Promise<void> {
    return UniverseRepository.update(
      {
        name: newName,
        id: this.id,
      },
    ).then((updatedUniverse: Universe) => {
      this.name = updatedUniverse.name;
    });
  }

  async setDescription(newDescription: object): Promise<void> {
    return UniverseRepository.update(
      {
        description: newDescription,
        id: this.id,
      },
    ).then((updatedUniverse: Universe) => {
      this.description = updatedUniverse.description;
    });
  }

  getOwner(): Authorization | undefined {
    let owner;
    this.authorizations.forEach((authorization: Authorization) => {
      if (authorization.accessLevel === 'owner') {
        owner = authorization;
      }
    });
    return owner;
  }

  isOwnedBy(user: User): boolean {
    return this.authorizations.some(
      (authorization) => authorization.userId === user.id && authorization.accessLevel === 'owner',
    );
  }

  getUserAuthorization(user: User): Authorization | undefined {
    let userAuthorization;
    this.authorizations.forEach((authorization) => {
      if (authorization.userId === user.id) {
        userAuthorization = authorization;
      }
    });
    return userAuthorization;
  }

  async delete() {
    await UniverseRepository.delete(this.id);
    store.commit('removeUniverse', this);

    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: this.id,
        message: `Deleted ${this.name}`,
        undoDelete: async() => {
          await UniverseRepository.undoDelete(this.id);
          store.commit('addUniverse', this);
        },
      },
    );
  }

  getEntityType(entityTypeId: string): EntityType | undefined {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    return entityTypes.find((entityType: EntityType) => entityType.id === entityTypeId);
  }

  async addEntityType(entityTypeRequest: Partial<EntityType>): Promise<EntityType> {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const entityType = await EntityTypeRepository.create(this.id, entityTypeRequest);
    await entityType.createCategory({ name: 'Overview' });
    entityTypes.push(entityType);
    return entityType;
  }

  async addExistingEntityType(entityTypeId: string): Promise<void> {
    await this.includeEntityTypes([entityTypeId]);
  }

  async reorderEntityTypes() {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const entityTypeOrder = entityTypes.map((entityType) => entityType.id);
    return UniverseRepository.reorderEntityTypes(this.id, entityTypeOrder);
  }

  async deleteEntityType(entityTypeId: string, countOfEntitiesAffected?: number) {
    await EntityTypeRepository.delete(entityTypeId);

    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const index = entityTypes.findIndex((entityType: EntityType) => entityType.id === entityTypeId);
    const [entityType] = entityTypes.splice(index, 1);

    let deletedMessage = `Removed ${entityType.pluralName} from ${this.name}`;
    if (countOfEntitiesAffected) {
      deletedMessage += ` causing ${countOfEntitiesAffected} ${entityType.pluralName} to be deleted`;
    }

    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: entityType.id,
        message: deletedMessage,
        undoDelete: async() => {
          await EntityTypeRepository.undoDelete(entityType.id);

          let insertIndex = 0;
          for (const existingEntityType of entityTypes) {
            if (existingEntityType.position > entityType.position) {
              break;
            }
            insertIndex += 1;
          }

          entityTypes.splice(insertIndex, 0, entityType);
        },
      },
    );
  }

  async includeEntityTypes(entityTypeIds: Array<string>) {
    store.state.entityTypesByUniverseId[this.id] = (await UniverseRepository.includeEntityTypes(this.id, entityTypeIds))
      .map((entityType: any) => new EntityType(entityType));
  }

  async addImage(file: File): Promise<void> {
    const image = await ImageRepository.addUniverseImage(this.id, file);
    this.images.push(image);
  }

  async addImageByUrl(url: string): Promise<void> {
    const image = await ImageRepository.addUniverseImageByUrl(this.id, url);
    this.images.push(image);
  }

  getImageUrl(): string | undefined {
    if (this.images.length > 0) {
      return `${COW_USER_IMAGE_URL}/${this.images[0].id}.${this.images[0].extension}`;
    }
  }

  getImageUrls(): string[] {
    const urls: string[] = [];
    this.images.forEach((image: Image) => {
      urls.push(`${COW_USER_IMAGE_URL}/${image.id}.${image.extension}`);
    });

    return urls;
  }

  async deleteImage(image: Image): Promise<void> {
    return ImageRepository.deleteUniverseImage(this.id, image.id).then(() => {
      const index = this.images.findIndex((candidateImage) => candidateImage.id === image.id);
      this.images.splice(index, 1);
      store.state.toasts.push(
        {
          componentName: 'UndoDeleteToast',
          key: image.id,
          message: `Deleted image for "${this.name}"`,
          undoDelete: async() => {
            await ImageRepository.undoDeleteUniverseImage(this.id, image.id);

            let insertIndex = 0;
            for (const existingImage of this.images) {
              if (existingImage.position > image.position) {
                break;
              }
              insertIndex += 1;
            }

            this.images.splice(insertIndex, 0, image);
          },
        },
      );
    });
  }

  isPowerUpEnabled(name: string) {
    // Return true only if power up is explicitly set to true
    return !!this.powerUps.find((powerUp) => powerUp.name === name && powerUp.enabled);
  }

  async configurePowerUp(name: string, enabled: boolean) {
    return UniverseRepository.configurePowerUp(this.id, name, enabled).then((powerUp) => {
      const oldConfigurationIndex = this.powerUps.findIndex(
        (existingConfiguration) => existingConfiguration.name === name,
      );
      if (oldConfigurationIndex !== undefined) {
        this.powerUps.splice(oldConfigurationIndex, 1);
      }
      this.powerUps.push(powerUp);
    });
  }

  async grantPermission(userId: string, accessLevel: string) {
    return UniverseRepository.grantPermission(this.id, userId, accessLevel).then((authorization) => {
      this.authorizations.push(authorization);
    });
  }

  async removePermissions(userId: string) {
    return UniverseRepository.removePermissions(this.id, userId).then(() => {
      this.authorizations = this.authorizations.filter((authorization) => authorization.userId !== userId);
    });
  }

  async searchTags(query: string): Promise<Array<string>> {
    return UniverseRepository.searchTags(this.id, query);
  }

  async getMetadata() {
    return UniverseRepository.getMetadata(this.id);
  }

  getEntityTypeOf(entity: AbridgedEntity): EntityType {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const typeIndex = entityTypes.findIndex(
      (entityType: EntityType) => entityType.id === entity.entityTypeId,
    );
    // It is up to the caller to ensure that the entity is from this universe
    // `as EntityType` will prevent type checking from finding a mistake
    return entityTypes[typeIndex];
  }
}
