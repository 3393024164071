import { AbridgedEntity } from '.';

export default class Relationship {
  id: string;

  universeId: string;

  fieldId: string;

  from: AbridgedEntity;

  to: AbridgedEntity;

  constructor(obj: any) {
    this.id = obj.id;
    this.universeId = obj.universeId;
    this.fieldId = obj.fieldId;
    this.from = new AbridgedEntity(obj.from);
    this.to = new AbridgedEntity(obj.to);
  }
}
