<template>
  <div
    :id="`undo-delete-toast-${$.uid}`"
    class="toast fade m-auto mb-1 mb-md-3 ms-md-auto me-md-3"
    role="status"
    aria-live="polite"
    aria-atomic="true"
    data-bs-delay="15000"
  >
    <div class="custom-toast-body text-bg-secondary">
      <strong class="me-auto">{{ message }}</strong>
      <form @submit.prevent="undoClicked">
        <button
          class="btn btn-secondary"
          data-bs-dismiss="toast"
        >
          Undo
        </button>
      </form>
      <button
        type="button"
        class="btn-close me-1"
        data-bs-dismiss="toast"
        aria-label="Close"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Toast from 'bootstrap/js/dist/toast';

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
    undoDelete: {
      type: Function,
      required: true,
    },
  },
  data: function() {
    return {
      sentUndo: false,
    };
  },
  mounted: function() {
    const toast = document.getElementById(`undo-delete-toast-${this.$.uid}`);
    if (toast) {
      // eslint-disable-next-line no-new
      new Toast(toast).show();
    }
  },
  methods: {
    undoClicked: function() {
      if (!this.sentUndo) {
        this.sentUndo = true;
        this.undoDelete();
        // TODO hide the toast?
      }
    },
  },
});
</script>

<style>
.custom-toast-body {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  border-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
</style>
