import '@popperjs/core';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/toast';

import { computed, createApp } from 'vue';
import { Store } from 'vuex';
import {
  Universe, EntityType, User, Authorization,
} from '@app/models';

import App from '@app/views/base.vue';

import UserRepository from '@app/repositories/userRepository';
import RequestOnceAtATime from '@app/utilities/requestOnceAtATime';

import store, { CowState } from '@app/store';
import router from '@app/router';
import { updateStoreBasedOnRoute } from '@app/updateStore';

const promise = UserRepository.get().then((user: User) => {
  store.commit('setLoggedInUser', user);
});

router.beforeEach((to) => {
  updateStoreBasedOnRoute(to);
});

function createVue() {
  const app = createApp(App);
  app.use(router);
  app.use(store);
  app.provide('user', computed<User | undefined>(() => store.state.loggedInUser));
  app.provide('universe', computed<Universe | undefined>(() => store.state.selectedUniverse));
  app.provide('entity-types', computed<Array<EntityType> | undefined>(
    () => {
      if (store.state.selectedUniverse) {
        return store.state.entityTypesByUniverseId[store.state.selectedUniverse.id];
      }
      return undefined;
    },
  ));
  app.provide('authorization', computed<Authorization | undefined>(
    () => {
      const user = store.state.loggedInUser;
      const universe = store.state.selectedUniverse;
      if (user && universe) {
        return store.state.actingAs || universe.getUserAuthorization(user);
      }
      return undefined;
    },
  ));
  app.provide('entity-type', computed<EntityType | undefined>(
    () => {
      const universe = store.state.selectedUniverse;
      if (!universe || !store.state.selectedEntityTypeId) {
        return undefined;
      }
      const entityTypes = store.state.entityTypesByUniverseId[universe.id];
      return entityTypes.find((entityType) => entityType.id === store.state.selectedEntityTypeId);
    },
  ));

  app.mount('#app');
}

Promise.all([promise]).then(() => createVue()).catch(() => createVue());

// Helpful for debugging
window.store = store;

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  if (event.reason.name === 'CowApiError' || event.reason.name === 'ClientError') {
    store.commit(
      'pushAlertMessage',
      `Error: ${event.reason.message}`,
    );
  }
  if (event.reason.name === 'NetworkError') {
    store.commit(
      'pushAlertMessage',
      'Connection error. You may be offline, or the website may be unavailable.',
    );
  }
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js');
}

// Send heartbeats that update when the user was last active
const heartbeatSender = new RequestOnceAtATime(
  async() => {
    if (store.state.loggedInUser) {
      return UserRepository.heartbeat();
    }
  },
);
heartbeatSender.send();
setInterval(
  () => {
    heartbeatSender.send();
  },
  1000 * 60 * 10,
);

// We store a reference here so that we can trigger a heartbeat any time the logged in user is set
store.commit('setHeartbeatSender', heartbeatSender);

// declare window.store to avoid type errors
declare global {
  interface Window { store: Store<CowState> }
}
