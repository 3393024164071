export default class RequestOnceAtATime {
  private hasInFlightRequest: boolean = false;

  private intervalId: ReturnType<typeof setInterval> | undefined = undefined;

  sendRequest: () => Promise<any>;

  constructor(sendRequest: () => Promise<any>) {
    this.sendRequest = sendRequest;
  }

  send() {
    // if this.intervalId is true, we are already waiting to send another request
    if (!this.intervalId) {
      // If there is a request in progress, check periodically if it has finished and
      // call send request again when it is done.
      this.intervalId = setInterval(
        () => {
          if (!this.hasInFlightRequest) {
            this.hasInFlightRequest = true;
            clearInterval(this.intervalId);
            this.intervalId = undefined;
            this.sendRequest().then(() => {
              this.hasInFlightRequest = false;
            });
          }
        },
        500,
      );
    }
  }
}
