import store from '@app/store';
import { User, SubscriptionPlan } from '@app/models';
import {
  get, post, patch, delete_,
} from '@app/utilities/fetchWrapper';
import { HttpError, CowApiError } from '@app/utilities/errorHandling';

interface LoginRequest {
  emailOrUsername: string;
  password: string;
  remember: boolean;
}

interface SignUpRequest {
  email: string;
  name: string;
  password: string;
}

interface RecoverPasswordRequest {
  email: string;
}

interface ResetPasswordRequest {
  resetToken: string;
  newPassword: string;
}

interface PasswordChangeRequest {
  newPassword: string;
  currentPassword: string;
}

export default {
  login: async function(data: LoginRequest): Promise<User | HttpError.Unauthorized> {
    const user = await post(
      `${COW_API_URL}/login`,
      {
        body: JSON.stringify(data),
      },
    );
    store.commit('setLoggedInUser', user);
    return user as User;
  },
  get: async function(): Promise<User> {
    return get(`${COW_API_URL}/me`);
  },
  update: async function(data: {name: string}): Promise<User> {
    const user = await patch(
      `${COW_API_URL}/me`,
      { body: JSON.stringify(data) },
    );
    return user as User;
  },
  getByName: async function(name: string): Promise<User | HttpError.NotFound> {
    try {
      return get(`${COW_API_URL}/users/${name}`);
    }
    catch (error) {
      if (error instanceof CowApiError) {
        if (error.httpStatus === 404) {
          return HttpError.NotFound;
        }
      }
      throw error;
    }
  },
  list: async function({
    query, page, pageSize, excludeSelf,
  }: {
    query?: string
    page?: number,
    pageSize?: number,
    excludeSelf?: boolean,
  } = {}): Promise<Array<User>> {
    let url = `${COW_API_URL}/users?`;
    if (query) {
      url += `search=${query}&`;
    }
    if (page) {
      url += `page=${page}&`;
    }
    if (pageSize) {
      url += `page_size=${pageSize}&`;
    }
    if (excludeSelf) {
      url += 'exclude_self=true&';
    }
    return get(url);
  },
  changePassword: async function(data: PasswordChangeRequest): Promise<User | HttpError.Unauthorized> {
    try {
      const user = await patch(
        `${COW_API_URL}/change_password`,
        { body: JSON.stringify(data) },
      );
      return user as User;
    }
    catch (error) {
      if (error instanceof CowApiError) {
        if (error.httpStatus === 401) {
          return HttpError.Unauthorized;
        }
      }
      throw error;
    }
  },
  recoverPassword: async function(data: RecoverPasswordRequest): Promise<void> {
    await post(
      `${COW_API_URL}/recover_password`,
      { body: JSON.stringify(data) },
    );
  },
  resetPassword: async function(data: ResetPasswordRequest): Promise<void> {
    await post(
      `${COW_API_URL}/reset_password`,
      { body: JSON.stringify(data) },
    );
  },
  signup: async function(data: SignUpRequest): Promise<User> {
    const user = await post(
      `${COW_API_URL}/signup`,
      { body: JSON.stringify(data) },
    );
    store.commit('setLoggedInUser', user);
    return user as User;
  },
  getPremiumPlan: async function(): Promise<SubscriptionPlan> {
    // For now we assume that exactly one plan exists and that it is the premium plan
    // If this ever changes, then we'll need to change this logic
    const [plan]: [SubscriptionPlan] = await get(`${COW_API_URL}/plans`);
    return plan;
  },
  getSubscribeUrl: async function(priceId: string): Promise<{url: string}> {
    return get(`${COW_API_URL}/get_subscribe_url/${priceId}`);
  },
  getManageSubscriptionUrl: async function(): Promise<{url: string}> {
    return get(`${COW_API_URL}/manage_subscription_url`);
  },
  logout: async function(): Promise<void> {
    await post(
      `${COW_API_URL}/logout`,
      { body: '' },
    );
    store.commit('clearState');
  },
  delete: async function(): Promise<User> {
    const deleted = await delete_(
      `${COW_API_URL}/me`,
      { body: '' },
    );
    return deleted as User;
  },
  heartbeat: async function(): Promise<void> {
    return patch(
      `${COW_API_URL}/me/heartbeat`,
      { body: '' },
    );
  },
};
