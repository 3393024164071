import { RelationshipRequirement } from '@app/models';
import {
  post, patch, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  create: async function(
    entityTypeId: string,
    categoryId: string,
    fieldId: string,
    relationshipRequirement: Partial<RelationshipRequirement>,
  ): Promise<RelationshipRequirement> {
    return new RelationshipRequirement(await post(
      `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${fieldId}`
        + '/relationship_requirements',
      { body: JSON.stringify(relationshipRequirement) },
    ));
  },
  update: async function(
    entityTypeId: string,
    categoryId: string,
    fieldId: string,
    relationshipRequirement: Partial<RelationshipRequirement>,
  ): Promise<RelationshipRequirement> {
    return new RelationshipRequirement(
      await patch(
        `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${fieldId}`
          + `/relationship_requirements/${relationshipRequirement.id}`,
        { body: JSON.stringify(relationshipRequirement) },
      ),
    );
  },
  delete: async function(
    entityTypeId: string,
    categoryId: string,
    fieldId: string,
    relationshipRequirementId: string,
  ): Promise<void> {
    await delete_(
      `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${fieldId}`
        + `/relationship_requirements/${relationshipRequirementId}`,
    );
  },
  undoDelete: async function(
    entityTypeId: string,
    categoryId: string,
    fieldId: string,
    relationshipRequirementId: string,
  ): Promise<RelationshipRequirement> {
    return new RelationshipRequirement(
      await patch(
        `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${fieldId}`
          + `/relationship_requirements/${relationshipRequirementId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ),
    );
  },
};
