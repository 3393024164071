import { Field } from '@app/models';
import {
  post, put, patch, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  create: async function(entityTypeId: string, categoryId: string, field: Partial<Field>): Promise<Field> {
    return post(
      `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields`,
      { body: JSON.stringify(field) },
    ).then((createdField: any) => new Field(createdField));
  },
  update: async function(entityTypeId: string, categoryId: string, field: Partial<Field>): Promise<Field> {
    return patch(
      `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${field.id}`,
      { body: JSON.stringify(field) },
    ).then((udpatedField: any) => new Field(udpatedField));
  },
  delete: async function(entityTypeId: string, categoryId: string, fieldId: string): Promise<void> {
    await delete_(`${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${fieldId}`);
  },
  undoDelete: async function(entityTypeId: string, categoryId: string, fieldId: string): Promise<Field> {
    return new Field(
      await patch(
        `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/deleted/${fieldId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ),
    );
  },
  reorder: async function(entityTypeId: string, categoryId: string, order: Array<string>): Promise<Array<string>> {
    return put(
      `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields`,
      { body: JSON.stringify({ ids: order }) },
    );
  },
  changeCategories: async function(entityTypeId: string, categoryId: string, fieldId: string) {
    return put(
      // eslint-disable-next-line max-len
      `${COW_API_URL}/entity_types/${entityTypeId}/categories/${categoryId}/fields/${fieldId}`,
      { body: '' },
    );
  },
};
