import { Image } from '@app/models';
import {
  put, post, patch, delete_,
} from '@app/utilities/fetchWrapper';

async function setImage(url: string, imageFile: File) {
  const data = new FormData();
  data.append('image', imageFile, imageFile.name);
  const imageDetails = await put(
    url,
    {
      body: data,
      headers: undefined,
    },
  );
  return new Image(imageDetails);
}

async function setImageByUrl(apiUrl: string, imageUrl: string) {
  const imageDetails = await put(
    apiUrl,
    {
      body: JSON.stringify({
        url: imageUrl,
      }),
    },
  );
  return new Image(imageDetails);
}

async function postImage(url: string, imageFile: File) {
  const data = new FormData();
  data.append('image', imageFile, imageFile.name);
  const imageDetails = await post(
    url,
    {
      body: data,
      headers: undefined,
    },
  );
  return new Image(imageDetails);
}

async function postImageByUrl(apiUrl: string, imageUrl: string) {
  const imageDetails = await post(
    apiUrl,
    {
      body: JSON.stringify({
        url: imageUrl,
      }),
    },
  );
  return new Image(imageDetails);
}

export default {
  setEntityTypeImage: function(universeId: string, entityTypeId: string, imageFile: File) {
    const url = `${COW_API_URL}/universes/${universeId}/entity_types/${entityTypeId}/images`;
    return setImage(url, imageFile);
  },
  setEntityImage: function(universeId: string, entityId: string, imageFile: File) {
    const url = `${COW_API_URL}/universes/${universeId}/entity/${entityId}/images`;
    return setImage(url, imageFile);
  },
  setEntityTypeImageByUrl: function(universeId: string, entityTypeId: string, imageUrl: string) {
    return setImageByUrl(
      `${COW_API_URL}/universes/${universeId}/entity_types/${entityTypeId}/images?by_url=true`,
      imageUrl,
    );
  },
  addEntityImage: function(universeId: string, entityId: string, imageFile: File) {
    return postImage(`${COW_API_URL}/universes/${universeId}/entities/${entityId}/images`, imageFile);
  },
  addEntityImageByUrl: function(universeId: string, entityId: string, imageUrl: string) {
    return postImageByUrl(`${COW_API_URL}/universes/${universeId}/entities/${entityId}/images?by_url=true`, imageUrl);
  },
  addUniverseImage: function(universeId: string, imageFile: File) {
    return postImage(`${COW_API_URL}/universes/${universeId}/images`, imageFile);
  },
  addUniverseImageByUrl: function(universeId: string, imageUrl: string) {
    return postImageByUrl(`${COW_API_URL}/universes/${universeId}/images?by_url=true`, imageUrl);
  },
  setImageByUrl: setImageByUrl,
  deleteEntityImage: async function(universeId: string, entityId: string, imageId: string): Promise<void> {
    await delete_(`${COW_API_URL}/universes/${universeId}/entities/${entityId}/images/${imageId}`);
  },
  undoDeleteEntityImage: async function(universeId: string, entityId: string, imageId: string) {
    await patch(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/images/deleted/${imageId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    );
  },
  deleteUniverseImage: async function(universeId: string, imageId: string): Promise<void> {
    await delete_(`${COW_API_URL}/universes/${universeId}/images/${imageId}`);
  },
  undoDeleteUniverseImage: async function(universeId: string, imageId: string) {
    await patch(
      `${COW_API_URL}/universes/${universeId}/images/deleted/${imageId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    );
  },
  deleteEntityTypeImage: async function(universeId: string, entityTypeId: string, imageId: string): Promise<void> {
    await delete_(`${COW_API_URL}/universes/${universeId}/entity_types/${entityTypeId}/images/${imageId}`);
  },
  undoDeleteEntityTypeImage: async function(universeId: string, entityTypeId: string, imageId: string): Promise<void> {
    await patch(
      `${COW_API_URL}/universes/${universeId}/entity_types/${entityTypeId}/images/deleted/${imageId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    );
  },
};
