<template>
  <div
    aria-live="polite"
    aria-atomic="true"
    class="position-fixed vw-100 bottom-0 end-0 unclickable"
  >
    <template
      v-for="toast in toasts"
      :key="toast.key"
    >
      <UndoDeleteToast
        v-if="toast.componentName === 'UndoDeleteToast'"
        :message="toast.message"
        :undo-delete="toast.undoDelete"
      />
      <ErrorMessageToast
        v-if="toast.componentName === 'ErrorMessageToast'"
        :message="toast.message"
      />
      <SuccessMessageToast
        v-if="toast.componentName === 'SuccessMessageToast'"
        :message="toast.message"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import store from '@app/store';
import UndoDeleteToast from '@app/components/generic/undoDeleteToast.vue';
import ErrorMessageToast from '@app/components/generic/errorMessageToast.vue';
import SuccessMessageToast from '@app/components/generic/successMessageToast.vue';

export default defineComponent({
  components: {
    UndoDeleteToast,
    ErrorMessageToast,
    SuccessMessageToast,
  },
  computed: {
    toasts: function() {
      return store.state.toasts;
    },
  },
});
</script>

<style scoped>
/* The div that contains our toasts extends across the entire page horizontally.
 * This lets us center the toasts on mobile sized devices. However, without
 * this class, it would block all clicks across that entire horizontal swathe of
 * the page.
 */
.unclickable {
  pointer-events: none;
}
</style>
