export default class Image {
  id: string;

  extension: string;

  thumbnailExtension: string;

  position: number;

  storedInBucket: boolean;

  constructor(obj: any) {
    this.id = obj.id;
    this.extension = obj.extension;
    this.thumbnailExtension = obj.thumbnailExtension;
    this.position = obj.position;
    this.storedInBucket = obj.storedInBucket;
  }
}
