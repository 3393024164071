import AccessLevel from '@app/constants/accessLevel';

export default class Authorization {
  userId: string;

  username: string;

  accessLevel: string;

  constructor(obj: any) {
    this.userId = obj.userId;
    this.username = obj.username;
    this.accessLevel = obj.accessLevel;
  }

  isAtLeast(accessLevel: string): boolean {
    const order = [
      AccessLevel.partialViewer,
      AccessLevel.viewer,
      AccessLevel.editor,
      AccessLevel.owner,
    ];
    return order.indexOf(this.accessLevel) >= order.indexOf(accessLevel);
  }

  isOwner(): boolean {
    return this.isAtLeast(AccessLevel.owner);
  }

  isAtLeastEditor(): boolean {
    return this.isAtLeast(AccessLevel.editor);
  }

  isAtLeastViewer(): boolean {
    return this.isAtLeast(AccessLevel.viewer);
  }

  isPartialViewer(): boolean {
    return this.accessLevel === AccessLevel.partialViewer;
  }
}
