import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-100b7014"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "aria-live": "polite",
  "aria-atomic": "true",
  class: "position-fixed vw-100 bottom-0 end-0 unclickable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UndoDeleteToast = _resolveComponent("UndoDeleteToast")!
  const _component_ErrorMessageToast = _resolveComponent("ErrorMessageToast")!
  const _component_SuccessMessageToast = _resolveComponent("SuccessMessageToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: toast.key
      }, [
        (toast.componentName === 'UndoDeleteToast')
          ? (_openBlock(), _createBlock(_component_UndoDeleteToast, {
              key: 0,
              message: toast.message,
              "undo-delete": toast.undoDelete
            }, null, 8 /* PROPS */, ["message", "undo-delete"]))
          : _createCommentVNode("v-if", true),
        (toast.componentName === 'ErrorMessageToast')
          ? (_openBlock(), _createBlock(_component_ErrorMessageToast, {
              key: 1,
              message: toast.message
            }, null, 8 /* PROPS */, ["message"]))
          : _createCommentVNode("v-if", true),
        (toast.componentName === 'SuccessMessageToast')
          ? (_openBlock(), _createBlock(_component_SuccessMessageToast, {
              key: 2,
              message: toast.message
            }, null, 8 /* PROPS */, ["message"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}