import {
  Entity, AbridgedEntity, Relationship, Image,
} from '@app/models';
import { Constraint } from '@app/models/searchConstraints';
import {
  get, post, patch, put, delete_,
} from '@app/utilities/fetchWrapper';
import { HttpError, ClientError, CowApiError } from '@app/utilities/errorHandling';

export default {
  get: async function(universeId: string, entityId: string): Promise<
    Entity
    | HttpError.Gone
    | HttpError.NotFound
    | HttpError.Forbidden
  > {
    try {
      return new Entity(await get<Partial<Entity>>(`${COW_API_URL}/universes/${universeId}/entities/${entityId}`));
    }
    catch (error) {
      if (error instanceof CowApiError) {
        if (error.httpStatus === 404) {
          return HttpError.NotFound;
        }
        if (error.httpStatus === 410) {
          return HttpError.Gone;
        }
        if (error.httpStatus === 403) {
          return HttpError.Forbidden;
        }
      }
      throw error;
    }
  },
  list: async function(
    universeId: string,
    entityTypeId?: string,
    page?: number,
    pageSize?: number,
    constraints?: Array<Constraint>,
  ): Promise<Array<AbridgedEntity>> {
    let url = `${COW_API_URL}/universes/${universeId}/entities?`;
    if (entityTypeId) {
      url += `entity_type=${entityTypeId}&`;
    }
    if (page) {
      url += `page=${page}&`;
    }
    if (pageSize) {
      url += `page_size=${pageSize}&`;
    }
    if (constraints) {
      url += `constraints=${encodeURIComponent(JSON.stringify(constraints))}&`;
    }
    const entities = await get<Array<Partial<Entity>>>(url);
    return entities.map((entity) => new AbridgedEntity(entity));
  },
  create: async function(universeId: string, entity: Partial<Entity>): Promise<Entity> {
    return new Entity(await post(
      `${COW_API_URL}/universes/${universeId}/entities`,
      { body: JSON.stringify(entity) },
    ));
  },
  update: async function(universeId: string, entity: Partial<Entity>): Promise<Entity> {
    return new Entity(await patch(
      `${COW_API_URL}/universes/${universeId}/entities/${entity.id}`,
      { body: JSON.stringify(entity) },
    ));
  },
  delete: async function(universeId: string, entityId: string): Promise<void> {
    await delete_(`${COW_API_URL}/universes/${universeId}/entities/${entityId}`);
  },
  undoDelete: async function(universeId: string, entityId: string): Promise<Entity> {
    return new Entity(await patch(
      `${COW_API_URL}/universes/${universeId}/entities/deleted/${entityId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    ));
  },
  createRelationship: async function(universeId: string, entityId: string, fieldId: string, otherId: string) {
    return new Relationship(await post(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/relationships/${fieldId}`,
      {
        body: JSON.stringify({
          id: otherId,
        }),
      },
    ));
  },
  deleteRelationship: async function(
    universeId: string,
    entityId: string,
    fieldId: string,
    relationshipId: string,
  ): Promise<void> {
    await delete_(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/relationships/${fieldId}/${relationshipId}`,
    );
  },
  undoDeleteRelationship: async function(
    universeId: string,
    entityId: string,
    fieldId: string,
    relationshipId: string,
  ): Promise<Relationship> {
    try {
      return new Relationship(await patch(
        `${COW_API_URL}/universes/${universeId}/entities/${entityId}/relationships`
          + `/deleted/${fieldId}/${relationshipId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ));
    }
    catch (error) {
      if (error instanceof CowApiError) {
        if (error.message === 'UNDOING_DELETE_CAUSED_CONFLICT') {
          throw new ClientError('Failed to undo deletion of relationship because it is already present.');
        }
      }
      throw error;
    }
  },
  setImage: async function(entityId: string, data: Blob): Promise<Image> {
    return new Image(await put(
      `${COW_API_URL}/images?entity_id=${entityId}`,
      {
        body: data,
        headers: undefined,
      },
    ));
  },
  createOrUpdateTextFieldValue: async function(
    universeId: string,
    entityId: string,
    fieldId: string,
    textFieldValue: { document: object },
  ): Promise<{ document: string }> {
    return put(
      `${COW_API_URL}/universes/${universeId}/entities/${entityId}/text_fields/${fieldId}`,
      { body: JSON.stringify(textFieldValue) },
    );
  },
};
