import { Universe, User } from '@app/models';

export default class EntityComment {
  id: string;

  text: string;

  document: any;

  poster: { id: string; name: string };

  entityId: string;

  universeId: string;

  createdAt: string;

  editedAt?: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.text = obj.text;
    this.document = obj.document;
    this.universeId = obj.universeId;
    this.entityId = obj.entityId;
    this.poster = obj.poster;
    this.createdAt = obj.createdAt;
    this.editedAt = obj.editedAt;
  }

  canDelete(universe: Universe, user: User) {
    const authorization = universe.getUserAuthorization(user);
    return this.poster.id === user.id
      || (authorization && authorization.isAtLeastEditor());
  }

  canEdit(user: User) {
    return this.poster.id === user.id;
  }
}
